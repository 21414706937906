import React from "react";
import { graphql } from 'gatsby';

import Helmet from "react-helmet";
import mixpanel from "mixpanel-browser";

import Layout from "../../components/layout";

import "./about.scss";

export default function Template({ data }) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;

  function trackOutbound() {
    mixpanel.track("about.social.click." + this);
  }

  return (
    <Layout>
      <div id="section-about" className="content">
        <Helmet title="About - Andrew Mahon" />

        <div className="" dangerouslySetInnerHTML={{ __html: html }} />

        <hr />

        <div className="about-contact-section">
          <p>Find me here...</p>
          <div className="social-links">
            <div className="link">
              <a
                href="https://www.facebook.com/andrew.whitcomb.mahon"
                className="icon-facebook facebook"
                onClick={trackOutbound.bind("facebook")}
              >
                Facebook
              </a>
            </div>
            <div className="link">
              <a
                href="https://www.linkedin.com/in/andrewmahon"
                className="icon-linkedin linkedin"
                onClick={trackOutbound.bind("linkedin")}
              >
                LinkedIn
              </a>
            </div>
            <div className="link">
              <a
                href="https://www.strava.com/athletes/259463"
                className="icon-strava strava"
                onClick={trackOutbound.bind("strava")}
              >
                Strava
              </a>
            </div>
            <div className="link">
              <a
                href="https://github.com/amahon"
                className="icon-github github"
                onClick={trackOutbound.bind("github")}
              >
                Github
              </a>
            </div>
          </div>
        </div>

        <div className="about-contact-section">
          <p>Get in touch...</p>
          <div className="preferred-contact">
            <div className="link">
              <a
                href="https://telegram.me/andrew_mahon"
                className="icon-telegram"
                onClick={trackOutbound.bind("telegram")}
              >
                Telegram
              </a>
            </div>
            <div className="link">
              <a
                href="andrewmahon@fastmail.com"
                className="icon-mail"
                onClick={trackOutbound.bind("mail")}
              >
                Email
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query AboutByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`;
